import React from 'react'
import { Card, Header, Icon, Image } from 'semantic-ui-react';
import example from "../../assets/habitTrackerPageExample.png";

const HabitPage: React.FC = () => {
    return (
        <>
            <Card centered style={{marginTop: '25px', width: '80%'}}>
                <Card.Content textAlign='center' header='Habit Tracker Project' />
                <Card.Description>
                    This personal project was created to help people keep track of habits they are working on creating or breaking.
                    It includes a user creation and login system and allows users to track the length of a habit and the number of cheat days
                    they wish to grant themselves.
                </Card.Description>
                <Card.Content>• Backend designed in C# .NET Core 5.0</Card.Content>
                <Card.Content>• Frontend designed in React using TypeScript and the Semantic UI framework</Card.Content>
                <Card.Content>
                    <Icon name='github' size='large'/>
                    <a
                        className="App-link"
                        href="https://github.com/dapowers87/habit-tracker"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        GitHub Repository
                    </a>
                </Card.Content>
                <Card.Content>
                    <Icon name='linkify' size='large'/>
                    <a
                        className="App-link"
                        href="https://habit-tracker.davidpowers.dev"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Project Website
                    </a>   
                </Card.Content>
                <Card.Content textAlign='center'>
                    <Header as='h3'>Example:</Header>
                    <Image bordered fluid src={example} size="huge"/>
                </Card.Content>
            </Card>
        </>
    )
}

export default HabitPage;