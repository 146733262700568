import React, { Fragment, useEffect } from "react";
import "./App.css";
import NavBar from "../features/nav/NavBar";
import { Route } from "react-router";
import ValuesLister from "../features/values/ValuesLister";
import { Container } from "semantic-ui-react";
import PersonDashboard from "../features/Persons/dashboard/PersonDashboard";
import HomePage from "../features/home/HomePage";
import PersonForm from "../features/Persons/form/PersonForm";
import { ToastContainer } from "react-toastify";
import Diagram from "../features/diagram/Diagram";
import MusicPage from "../features/MusicPage/MusicPage";
import HabitPage from "../features/HabitPage/HabitPage";
import FanRemotePage from "../features/FanRemotePage/FanRemotePage";
import ChickenCoopPage from "../features/ChickenCoopPage/ChickenCoopPage";
import agent from "./api/agent";
import { useHistory } from "react-router-dom"

const App: React.FC = () => {

  const history = useHistory();

  history.listen( async (location: any, _: any) => {
    await agent.Visit.log(location.pathname);
  });

  useEffect(() => {
    agent.Visit.log(history.location.pathname);
  }, [])

  return (
    <Fragment>
      <ToastContainer position="bottom-right" />
      <Route exact path={"/"} component={HomePage} />
      <NavBar />
      <Route
        path={"/(.+)"}
        render={() => (
          <Container style={{ paddingTop: "7em" }}>
            {/* <Route path="/ValuesLister" component={ValuesLister} /> */}
            {/* <Route path="/Persons" component={PersonDashboard} /> */}
            <Route path="/Diagram" component={Diagram} />
            {/* <Route path="/Music" component={MusicPage} /> */}
            <Route path="/HabitTracker" component={HabitPage} />
            <Route path="/FanRemote" component={FanRemotePage} />
            <Route path="/ChickenCoop" component={ChickenCoopPage} />
            {/* <Route path="/Person/:id" component={PersonForm} /> */}
            {/* <Route
              path={["/createPerson", "/manage/:id"]}
              component={PersonForm}
            /> */}
          </Container>
        )}
      />
    </Fragment>
  );
};

export default App;
