import React from "react";
import logo from "../../assets/logo.png";
import {Icon, Image} from 'semantic-ui-react'

const HomePage = () => {
  return (
    <div className="App">
      <header className="App-header">
        <Image centered style={{resizeMode:'contain'}} src={logo} alt="logo" size='large'/>
        <br/>
        <p>
          <a
            className="App-link"
            href="./resume/Powers_David_Resume.pdf"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Icon name='file pdf' size='large'/>
            My Resume
          </a>
          <a
            className="App-link"
            href="https://github.com/dapowers87"
            target="_blank"
            rel="noopener noreferrer"
            style={{marginLeft: '50px'}}
          >
            <Icon name='github' size='large'/>
            My GitHub Profile
          </a>
        </p>
      </header>
    </div>
  );
};

export default HomePage;
