import React from 'react'
import { Card, Header, Icon, Image } from 'semantic-ui-react';
import example from "../../assets/RemoteControl.jpg";
import example2 from "../../assets/Fan_Wiring.jpeg";
import example3 from "../../assets/Fan_Remote_HA.png";
import example4 from "../../assets/Fan_Remote_HA2.png";
import { Link } from 'react-router-dom';

const FanRemotePage: React.FC = () => {
    return (
        <>
            <Card centered style={{marginTop: '25px', width: '80%'}}>
                <Card.Content textAlign='center' header='Fan Remote Project' />
                <Card.Description>
                    <p>
This personal project was created so that I could control two ceiling fans via a cannibalized RF remote control in my house. 
                    </p>
                    <p>
The controller is integrated with the home automation application,&#160;
 <Link to="https://www.home-assistant.io/">
    Home Assistant
</Link>, which allows me to control my ceiling fans with both my phone and through Google Assistant. 
                    </p>
                    <p>
The application communicates with Home Assistant via MQTT. I wrote the application in Python after taking a self-guided course on Udemy. The application controls a Raspberry Pi Zero W that is wired up to the two ceiling fans' shared cannibalized remote control.
                    </p>
                </Card.Description>
                <Card.Content>• Project written in Python 3</Card.Content>
                <Card.Content>
                    <Icon name='github' size='large'/>
                    <a
                        className="App-link"
                        href="https://github.com/dapowers87/FanRemoteControl"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        GitHub Repository
                    </a>
                </Card.Content>
                <Card.Content textAlign='center'>
                    <Header as='h3'>Raspberry Pi / Remote Control Solution</Header>
                    <Image bordered fluid src={example} size="large"/>
                </Card.Content>
                <Card.Content textAlign='center'>
                    <Header as='h3'>Home Assistant Integration</Header>
                    <Image bordered fluid src={example4} size="large"/>
                    <Image bordered fluid src={example3} size="large"/>
                </Card.Content>
                <Card.Content textAlign='center'>
                    <Header as='h3'>Prototype</Header>
                    <Image bordered fluid src={example2} size="large"/>
                </Card.Content>
            </Card>
        </>
    )
}

export default FanRemotePage;