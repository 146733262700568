import React from 'react'
import { Card, Header, Icon, Image } from 'semantic-ui-react';
import example from "../../assets/ChickenCoopDoor.jpg";
import example2 from "../../assets/ChickenCoop_HA.png";
import example3 from "../../assets/ChickenGang.jpg";
import example4 from "../../assets/chicken_nest_cam_pic.jpg";
import { Link } from 'react-router-dom';

const ChickenCoopPage: React.FC = () => {
    return (
        <>
            <Card centered style={{marginTop: '25px', width: '80%'}}>
                <Card.Content textAlign='center' header='Chicken Coop Door Project' />
                <Card.Description>
                    <p>
                This personal project was created to control a chicken coop door I built. 
The door is wired to a Raspberry Pi 4. The Pi controls the door's motor as well 
as detects whether the door is open or closed.
                    </p>
                    <p>
The script that controls the door isn't the cleanest as I wrote it having no prior experience in Python. My&#160;
<Link to="/FanRemotePage">
    Fan Remote project
</Link> has much cleaner Python coding practices.
                    </p>
                    <p>
The door is integrated with the home automation application,&#160;
<Link to="https://www.home-assistant.io/">
    Home Assistant
</Link>, which allows me to control 
it with both my phone and through Google Assistant. The application communicates with Home Assistant
via MQTT.
                    </p>
                </Card.Description>
                <Card.Content>• Project written in Python 2</Card.Content>
                <Card.Content>
                    <Icon name='github' size='large'/>
                    <a
                        className="App-link"
                        href="https://github.com/dapowers87/ChickenCoop"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        GitHub Repository
                    </a>
                </Card.Content>
                <Card.Content textAlign='center'>
                    <Header as='h3'>Home Assistant Integration:</Header>
                    <Image bordered fluid src={example2} size="large"/>
                </Card.Content>
                <Card.Content textAlign='center'>
                    <Header as='h3'>Interior Chicken Nest Camera</Header>
                    <Image bordered fluid src={example4} size="large"/>
                </Card.Content>
                <Card.Content textAlign='center'>
                    <Header as='h3'>Chicken Coop Door</Header>
                    <Image bordered fluid src={example} size="medium"/>
                </Card.Content>
                <Card.Content textAlign='center'>
                    <div className="embed-container">
                        <iframe width="100%" height="100%" src="https://www.youtube-nocookie.com/embed/zWPRJoo6xOg?si=0xHXN40bnhJ_k9VR" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"></iframe>
                    </div>
                </Card.Content>
                <Card.Content textAlign='center'>
                    <Header as='h3'>The Crew</Header>
                    <Image bordered fluid src={example3} size="huge"/>
                </Card.Content>
            </Card>
        </>
    )
}

export default ChickenCoopPage;